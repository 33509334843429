<template>
  <div class="hello">
    <img class="bg-img">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  padding-top: 200px;
  background-color: #292929;
  display: flex;
  height: 4800px;
  flex-grow: 1;
}

.bg-img {
  /* The image used */
  background-image: url("../assets/image.png");

  /* Full height */
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-block{
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding : 10px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
